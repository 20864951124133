import { defineStore } from "pinia";
import { useQuasar } from "quasar";
import axios from "axios";
import { useRouter } from "vue-router";
import { useAuthenticationStore } from "store/modules/authenticationStore.js";

/**
 * Authorization Store
 *
 * This store manages the roles used in the application.
 */
export const useAuthorizationStore = defineStore("authorization", () => {
  // Quasar
  const $q = useQuasar();
  const router = useRouter();

  // Authenthication Store
  const authenticationStore = useAuthenticationStore();
  const { processResults } = authenticationStore;

  // Refs
  const isImpersonating = localStorage.getItem("webmaster_refresh_token")
    ? localStorage.getItem("webmaster_refresh_token")
    : false;

  /**
   * The roles used in the application.
   * @type {Array<Object>}
   * @property {string} label - The display label of the role.
   * @property {string} name - The internal name of the role.
   */
  const allRoles = [
    {
      label: "Webmaster",
      name: "webmaster",
    },
    {
      name: "manager",
      label: "Applicatiebeheerder",
    },
    {
      name: "organizer",
      label: "Organisator",
    },
    {
      name: "teamleader",
      label: "Teamleider",
    },
    {
      name: "decaan",
      label: "Organisator portfolio",
    },
    {
      name: "teacher",
      label: "Docent",
    },
    {
      name: "student",
      label: "Student",
    },
  ];

  const impersonateUser = async ({ userId = null } = {}) => {
    if (!userId) {
      $q.notify({
        message: "Geen employeeID meegestuurd.",
        type: "negative",
      });
    }

    try {
      const { data } = await axios.post(`/api/impersonate/${userId}`);

      const webmasterRefreshToken = localStorage.getItem("refresh_token");
      localStorage.setItem("webmaster_refresh_token", webmasterRefreshToken);

      $q.notify({
        message:
          "Je bent wordt nu ingelogd als .... , de pagina wordt opnieuw geladen.",
        type: "positive",
      });

      await processResults(data);

      return data;
    } catch (error) {
      $q.notify({
        message: "Er is iets fout gegaan bij het inloggen.",
        type: "negative",
      });
    }
  };

  const returnAsWebmaster = async () => {
    try {
      const webmasterRefreshToken = localStorage.getItem(
        "webmaster_refresh_token",
      );

      const { data } = await axios.post("/api/impersonate/return", {
        refresh_token: webmasterRefreshToken,
      });

      localStorage.removeItem("webmaster_refresh_token");
      await processResults(data);

      return data;
    } catch (error) {
      console.log(error);
      $q.notify({
        message:
          "Er is iets fout gegaan bij het inloggen, je moet opnieuw inloggen. De pagina wordt opnieuw geladen.",
        type: "negative",
      });

      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 2000);
    }
  };

  return {
    allRoles,
    isImpersonating,
    impersonateUser,
    returnAsWebmaster,
  };
});
