import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "axios";

/**
 * Authorization Store
 *
 * This store manages the roles used in the application.
 */
export const useAuthenticationStore = defineStore("authentication", () => {
  // State
  const user = ref({});
  const status = ref({ loggedIn: false });
  const errors = ref([]);

  // Actions
  const getUser = () => {
    if (!status.value.loggedIn) {
      invalidUser();
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        axios
          .get("/api/user/current")
          .then((response) => {
            setUser(response.data);
            resolve();
          })
          .catch(() => {
            invalidUser();
            resolve();
          });
      }, 1000);
    });
  };

  const setErrorMessages = (errorMessages) => {
    setErrors(errorMessages);
  };

  const loginUser = async (userInput) => {
    let errorArray = [];

    try {
      const { data } = await axios.get(`/api/user-by-email/${userInput.email}`);
      const cookie = getCookie("TwoFactor" + data);

      const { data: oauthData } = await axios.post(
        "/oauth/login",
        {
          email: userInput.email,
          password: userInput.password,
          remember_me: userInput.rememberMe,
          cookie,
        },
        {
          withCredentials: true,
        },
      );

      processResults(oauthData);
    } catch (error) {
      return Promise.reject(error);
    }
    return Promise.resolve();
  };

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const processResults = (result) => {
    if (!result.access_token) {
      return;
    }

    localStorage.setItem("user_name", result.user.username);
    localStorage.setItem("first_name", result.user.first_name);
    localStorage.setItem("user", JSON.stringify(result.user));
    localStorage.setItem("school_id", result.user.school_id);
    localStorage.setItem("school_name", result.user.school_name);
    localStorage.setItem("tool", result.user.tool);
    localStorage.setItem("school_hash", result.user.school_hash);
    localStorage.setItem("show_nps", result.show_nps);

    let role_user_id = result.user.role_user_id;
    if (role_user_id) {
      let roles = result.user.role;

      if (
        roles.includes("teacher") ||
        roles.includes("decaan") ||
        roles.includes("teamleader") ||
        roles.includes("organizer") ||
        roles.includes("manager")
      ) {
        localStorage.setItem("employee_id", role_user_id);
        localStorage.removeItem("student_id");
        localStorage.removeItem("student_user_id");
      } else if (roles.includes("student")) {
        localStorage.setItem("student_id", role_user_id);
        localStorage.removeItem("employee_id");
      }
    }

    // Save token
    localStorage.setItem("user_token", result.access_token);
    localStorage.setItem("refresh_token", result.refresh_token);

    if (result.user.validate_2fa && result.user.unique_device_code) {
      if (!result.user.impersonating) {
        localStorage.setItem("validate_2fa", "true");
      }

      let d = new Date();
      d.setDate(d.getDate() + 30);
      let expires = "expires=" + d.toUTCString();

      document.cookie =
        "TwoFactor" +
        result.user.id +
        "=" +
        result.user.unique_device_code +
        ";" +
        expires +
        ";path=/";
    }

    let dt = new Date().getTime();
    dt = new Date(dt + parseInt(result.expires_in) * 1000);
    localStorage.setItem("expires_in", dt.toLocaleString());

    if (result.schools) {
      localStorage.setItem("school_choices", JSON.stringify(result.schools));
      localStorage.setItem("menu_locations", JSON.stringify(result.schools));
    }

    setUser(result);
    window.location.href = "/";
  };

  // Mutations (refactored as functions)
  const setErrors = (data) => {
    errors.value = data;
  };

  const setUser = (data) => {
    status.value.loggedIn = true;
    user.value = data;
  };

  const invalidUser = () => {
    status.value.loggedIn = false;
    user.value = null;
  };

  return {
    user,
    status,
    errors,
    getUser,
    setErrorMessages,
    loginUser,
    processResults,
  };
});
