<template>
  <q-toolbar
    class="quasar-menu tw-relative tw-z-10 tw-border-b tw-border-t-8 tw-border-secondary tw-border-b-gray-100 tw-bg-white"
  >
    <div
      class="tw-container tw-mx-auto tw-flex tw-w-full tw-items-center tw-justify-between"
    >
      <div class="tw-flex tw-items-center">
        <c-button class="!tw-font-medium" to="/" flat>{{
          schoolname
        }}</c-button>
        <div v-if="isImpersonating" class="tw-text-2xs">als {{ userName }}</div>
      </div>

      <div class="tw-hidden lg:tw-block">
        <q-tabs no-caps>
          <q-route-tab
            class="quasar-menu__tab"
            v-for="(item, key) in menuItems"
            :key="`item-${key}`"
            :to="{
              name: item.name,
            }"
            @click="item.children?.length && $event.preventDefault()"
          >
            <q-badge
              class="tw-w-5 !tw-text-[10px]"
              v-if="
                item.name === 'taskIndex' && user.portfolio?.open_tasks_amount
              "
              :class="{
                '!tw-px-1': user.portfolio?.open_tasks_amount > 9,
              }"
              rounded
              color="red"
              floating
              >{{ user.portfolio?.open_tasks_amount }}</q-badge
            >
            <div>
              {{ generateMenuTitle(item) }}

              <q-icon
                v-if="item.children && item.children.length"
                size="20px"
                name="arrow_drop_down"
              />
            </div>

            <c-menu v-if="item.children && item.children.length">
              <q-list dense>
                <q-item
                  v-for="(subItem, key) in item.children"
                  :key="`subitem-${key}`"
                  clickable
                  v-close-popup
                  :to="{
                    name: subItem.name,
                  }"
                >
                  <q-item-section>{{
                    generateMenuTitle(subItem)
                  }}</q-item-section>
                </q-item>
              </q-list>
            </c-menu>
          </q-route-tab>

          <q-tab class="quasar-menu__tab--small" v-if="canSeeHelpMenuItems">
            <q-icon
              name="fa-light fa-circle-question fa-swap-opacity"
              size="20px"
            />
            <c-menu>
              <q-list dense>
                <q-item
                  v-for="item in helpItems"
                  :key="item.label"
                  clickable
                  v-close-popup
                  :to="item.to"
                  :href="item.href"
                  :target="item.href ? '_blank' : ''"
                  class="tw-flex tw-items-center"
                >
                  <q-item-section class="tw-flex-grow">{{
                    item.label
                  }}</q-item-section>
                  <q-icon
                    v-if="item.href"
                    name="fa-solid fa-arrow-up-right-from-square"
                    size="10px"
                    class="tw-flex-shrink-0"
                  />
                </q-item>
              </q-list>
            </c-menu>
          </q-tab>

          <q-route-tab
            class="quasar-menu__tab--small"
            :to="{
              name: 'settings',
            }"
            v-if="globals.showSettingsPage"
          >
            <q-icon name="fa-light fa-gear" size="20px" />
          </q-route-tab>

          <q-route-tab
            class="quasar-menu__tab--small"
            :to="{
              name: 'notifications',
            }"
          >
            <q-badge
              v-if="unreadNotificationsCount"
              class="!-tw-right-px tw-w-5 !tw-text-[10px]"
              :class="{
                '!tw-px-1': unreadNotificationsCount > 9,
              }"
              color="red"
              floating
              rounded
              >{{ unreadNotificationsCount }}</q-badge
            >
            <q-icon name="fa-light fa-bell" size="20px" />
          </q-route-tab>

          <q-tab class="quasar-menu__tab--small" v-if="locations?.length">
            <q-icon name="fa-thin fa-location-dot" size="20px" />
            <c-menu>
              <q-list dense>
                <q-item
                  v-for="(location, key) in locations"
                  :key="key"
                  clickable
                  v-close-popup
                  @click="loginAsSchool(location)"
                >
                  <q-item-section>{{ location.name }}</q-item-section>
                </q-item>
              </q-list>
            </c-menu>
          </q-tab>

          <logout-button />
        </q-tabs>
      </div>

      <div class="lg:tw-hidden">
        <q-btn @click="toggleMenu" flat round dense icon="menu" />
      </div>
    </div>
  </q-toolbar>

  <SubMenu :items="subMenuItems" v-if="!hideSubmenu" />
  <SubMenuTabs v-if="!hideSubmenu" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useGlobalStore } from "@/stores/global/global";
import { storeToRefs } from "pinia";
import SubMenu from "components/global/SubMenu.vue";
import { useMenuStore } from "store/modules/menuStore.js";
import SubMenuTabs from "components/global/SubMenuTabs.vue";
import useHelpers from "composables/useHelpers";
import { useUserStore } from "store/modules/userStore.js";
import { useNotificationStore } from "store/modules/notificationStore.js";
import LogoutButton from "components/global/LogoutButton.vue";
import { useAuthorizationStore } from "store/modules/authorizationStore.js";

// menuStore
const menuStore = useMenuStore();
const { toggleMenu, loginAsSchool } = menuStore;
const { menuItems, helpItems, canSeeHelpMenuItems, subMenuItems, hideSubmenu } =
  storeToRefs(menuStore);

// Helpers
const { generateMenuTitle, userName } = useHelpers();

// notificationStore
const notificationStore = useNotificationStore();
const { unreadNotificationsCount } = storeToRefs(notificationStore);

// Authorization Store
const autorizationStore = useAuthorizationStore();
const { isImpersonating } = autorizationStore;

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const globalStore = useGlobalStore();
const { globals } = storeToRefs(globalStore);

const locations = ref([]);
const schoolname = ref("");

onMounted(() => {
  locations.value = JSON.parse(localStorage.getItem("menu_locations"));
  schoolname.value = localStorage.getItem("school_name");
});
</script>

<style lang="scss" scoped>
.q-item.q-router-link--active {
  @apply tw-bg-[#EBEBEE];
}
</style>

<style lang="scss">
.quasar-menu {
  &__tab {
    @apply tw-px-2.5;

    &--small {
      @apply tw-px-0;
    }
  }

  .q-router-link--active {
    .q-tab__indicator {
      opacity: 1 !important;
    }
  }

  .q-tab--active:not(.q-router-link--active) {
    .q-tab__indicator {
      opacity: 0 !important;
    }
  }
}
</style>
